import styles from './PartnersSection.module.scss';
import avalanche from '../img/partners/avalanche.png';
import klaytn from '../img/partners/kaia-logo.png';
import metis from '../img/partners/metis-logo.png';
import synapse from '../img/partners/synapse.png';
import layerZero from '../img/partners/layer-zero.png';
import wisdomLabs from '../img/partners/wisdom-labs.png';
import rbl from '../img/partners/rbl-logo.png';
import coinbase from '../img/partners/coinbase.png';

const partners = [
  {
    name: 'avalanche',
    image: avalanche,
    link: 'https://www.avax.network/',
    width: 203,
  },
  {
    name: 'metis',
    image: metis,
    link: 'https://metis.io/',
    width: 178,
  },
  {
    name: 'kaia',
    image: klaytn,
    link: 'https://www.kaia.io/',
    width: 178,
  },
  {
    name: 'layer zero',
    image: layerZero,
    link: 'https://layerzero.network/',
    width: 203,
  },
  {
    name: 'synapse',
    image: synapse,
    link: 'https://synapseprotocol.com/',
    width: 169,
  },
  {
    name: 'coinbase',
    image: coinbase,
    link: 'https://www.coinbase.com/wallet',
    width: 168,
  },
];

function PartnersSection() {
  return (
    <section className={styles.partners}>
      <h2>Partnerships</h2>
      <p>
        Close relationships with our partners helps keep DeFi Kingdoms realms and DFK Chain running
        smoothly. We have partnerships with chains, bridges, game developers, RPCs, onramps,
        marketing, DAOs, DEX and CEX companies:
      </p>
      <div className={styles.partnerWrapper}>
        {partners.map((partner) => (
          <a key={partner.name} href={partner.link} target="_blank" rel="noreferrer">
            <img src={partner.image} alt="" style={{ width: partner.width }} />
          </a>
        ))}
      </div>
    </section>
  );
}

export default PartnersSection;
